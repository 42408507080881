.layout {
  height: 100vh;
  .headerLayout {
    text-align: center;
    height: 64px;
    background-color: #ffffff;
    padding: 15px 10px;
  }
  .contentLayout {
    height: calc(100vh - 64px - 55px);
    overflow-x: hidden;
    overflow-y: auto;
  }
  .footerLayout {
    height: 55px;
    background: #ffffff;
    border-top: 1px solid #e7e7e7;
    .footerMenu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: inherit;
      .footerLink {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: calc(100% / 5);
        height: inherit;
        .title {
          position: absolute;
          font-size: 9px;
          top: 35px;
        }
        .icon {
          position: absolute;
          top: 12px
        }
      }
    }
  }
}

.userInfo {
  padding: 30px 15px 10px;
  .detail {
    display: flex;
    align-items: center;
    .avatar {
      background: #87d068;
    }
    .userName {
      color: #ffffff;
      text-transform: capitalize;
      padding-left: 0.5rem !important;
      margin-bottom: 0px;
    }
    .userType {
      color: #ffffff;
      font-size: 13px;
      padding-left: 0.5rem !important;
    }
  }
  .userAccount {
    margin: 20px 0 10px 0;
  }
}
