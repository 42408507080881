.guidanceContainer {
  .header {
    text-align: center;
    margin: 20px 0;
    .title {
      font-size: 30px;
      font-weight: 700px;
    }
  }
  .content {
    .searchBox {
      position: relative;
      width: 100%;
      max-width: 540px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
      .search {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: none;
        border-radius: 8px;
        outline: 0;
        box-shadow: none;
        font-size: 15px;
        line-height: 21px;
        height: 46px;
        width: 100%;
        background: #1E2835;
        color: #798DA3;
        padding: 12px 20px 12px 46px;
      }
      img {
        position: absolute;
        left: 17px;
        top: 14px;
      }
    }
    .guidanceVideos {
      width: 100%;
      height: 580px;
      overflow: auto;
      margin: auto;
      max-width: 1140px;
      :global {
        .ant-collapse {
          border: none !important;
          &-item {
            background: #1E2835;
            border-radius: 20px;
            border-bottom: none;
            margin-bottom: 16px;
            padding: 16px;
            .ant-collapse-header {
              position: relative;
              font-size: 18px;
              color: #ffffff;
              line-height: 1.4;
              padding: 0px;
            }
            .ant-collapse-content {
              background: #1E2835;
              margin: 10px 0 0 20px;
              border-top: 1px solid rgba(255, 255, 255, 0.1);
              &-box {
                padding: 16px 0;
                font-size: 15px;
                line-height: 21px;
                font-weight: 500;
                color: #798DA3;
              }
            }
          }
        }
      }
    }
    .guidanceVideos::-webkit-scrollbar {
      width: 0px;
    }
  }
}
