.botTable {
  :global {
    .ant-card {
      .ant-card-body {
        padding: 15px 5px !important;
        .ant-table-cell {
          padding: 16px 5px !important;
        }
      }
    }
  }
}
