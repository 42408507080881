.searchItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .refreshBtn {
    text-align: center;
    button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin: 15px 7px 0 7px;
      width: 45%;
    }
  }
  .searchWrapper {
    --input-height: 38px;
    position: relative;
    width: 100%;
    margin-right: 20px;
    .wrapper {
      position: relative;
      box-sizing: border-box;
      display: flex;
      width: 100%;
      height: 38px;
      padding: 8px 12px;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.005em;
      transition: background-color 0.2s cubic-bezier(0.4,0,0.2,1);
      border: 1px solid rgb(217, 217, 217);
      border-radius: 8px;
      .iconSearch {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin-right: 8px;
      }
      input {
        width: 100%;
        font: inherit;
        background-color: transparent;
        border: none;
        outline: none;
      }
      .clearSearch {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        .clearBtn {
          display: flex;
          padding: 0;
          cursor: pointer;
          background-color: transparent;
          border: none;
          outline: none;
          visibility: hidden;
          opacity: 0;
          transition: opacity 0.2s cubic-bezier(0.4,0,0.2,1);;
        }
        .clearBtn.show {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
  .filterOptions {
    width: 100%;
    height: 650px;
    overflow-x: hidden;
    overflow-y: auto;
    .filterOption {
      width: 100%;
      padding: 5px 20px;
    }
  }
}

.itemCard {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 10px;
  border: 2px double rgb(148, 135, 127);
  border-radius: 4px;
  cursor: pointer;
  .itemImage {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
    img {
      width: 50%;
      border-radius: 5px;
    }
  }
  .detail {
    padding: 0px 10px;
    span {
      display: block;
      color: #7a7a7f;
      font-size: 13px;
    }
    .price {
      color: #000000;
      font-weight: bold;
      font-size: 16px;
      margin-top: 5px;
    }
  }
  .action {
    width: 100%;
  }
}
.addCart:hover {
  background: #4096ff;
  svg {
    fill: #ffffff;
  }
}

:global(.ant-table-wrapper) {
	// overflow-y: auto;
}
.couponContainer {
  margin-top: 5px;
  .couponTitle {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
  }
  .couponDetail {
    margin: 5px 0 20px 0;
  }
  .couponBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: #e2e1e1;
    border-radius: 5px;
    padding: 0px 10px;
    font-weight: 500;
    color: #606060;
  }
  .couponBtn:hover {
    background: #e9e8e8;
  }
  .couponErr {
    position: absolute;
    bottom: -22px;
    left: 8px;
    font-size: 13px;
    color: #ff4d4f;
  }
}
.couponCard {
  .couponCardContainer {
    display: flex;
    align-items: center;
    // width: 70%;
    height: 90px;
    border: 0.0625rem solid transparent;
    border-left: none;
    box-shadow: var(--vc-template-box-shadow, 0.125rem 0.125rem 0.3125rem rgba(0, 0, 0, .07));
    box-sizing: border-box;
    .couponTag {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #1677ff;
      height: 100%;
    }
    .couponCardDetail {
      flex-grow: 2;
      height: 100%;
      padding-top: 20px;
      margin-left: 10px;
      color: rgba(0,0,0,.8);
      .couponName {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.25rem;
      }
      .couponDiscount {
        font-size: 14px;
        font-weight: normal;
        line-height: 1rem;
        margin-top: 5px;
      }
    }
  }
}
