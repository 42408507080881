.mobilePopupContainer {
  .mobilePopupBox {
    position: fixed;
    bottom: 00px;
    left: 0;
    height: 500px;
    width: 100%;
    border-radius: 20px 20px 0px 0px;
    background-color: #ffffff;
    z-index: 1;
    // animation: show 0.7s ease forwards;
    .mobilePopupHeader {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      padding: 5px 20px;
      border-radius: 20px 20px 0px 0px;
      border-bottom: 1px solid rgb(207, 207, 207);
      .closeBtn {
        position: absolute;
        top: 5px;
        right: 10px;
        background: none;
      }
      .headerTitle {
        font-size: 15px;
        font-weight: bold;
      }
    }
    .xxx {
      
    }
  }
}

@keyframes show {
  0% {
    transform: translateY(500px);
  }
  100% {
    transform: translateY(0px);
  }
}